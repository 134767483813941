import React from 'react'
import {useTranslate} from 'react-polyglot'
import {Box, Flex} from '@chatterbug/aaron'
import LocalizedLink from "../../../../components/LocalizedLink/LocalizedLink";

const PricingPlanFooter: React.FC = () => {
  const t = useTranslate()
  return (
    <Flex alignItems="center" flexDirection="column">
      <Box
        variant="text.paragraph"
        textAlign="center"
        dangerouslySetInnerHTML={{
          __html: t(
            'Want your <strong>employer</strong> or <strong>school</strong> to pay for your Chatterbug plan?'
          ),
        }}
      />
      <Flex gridGap=".3rem">
        <Box variant="text.paragraph" mr="05x">
          {t('Tell them about')}
        </Box>
        <LocalizedLink to="/business">
          <Box
            variant="text.paragraph"
            fontWeight="semiBold"
            color="blue42"
            sx={{
              ':hover': {
                color: 'blue30',
              },
            }}
          >
            {t('Chatterbug for Business')}
          </Box>
        </LocalizedLink>
      </Flex>
    </Flex>
  )
}

export default PricingPlanFooter
